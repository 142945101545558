import * as React from 'react';
import {DesktopDatePicker} from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers';
import {Controller} from "react-hook-form";
import TextField from "@mui/material/TextField";
import 'dayjs/locale/da';

export default function DateInput({control, errors, name, label, id,maxWidth, fullwidth, validateIndput, pattern}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'da'}>
            <Controller
                name={name}
                control={control}
                rules={{
                    required: "Felt mangler",
                    pattern: {
                        value: pattern != null ? pattern : undefined,
                        message: "Ugyldigt input"
                    },
                    validate: validateIndput
                }}
                render={({field: {ref, ...rest}}) => (
                    <DesktopDatePicker
                        label={label}
                        id={id}
                        inputFormat="DD.MM.YY"
                        fullWidth={!!fullwidth}
                        disableFuture
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                sx={{
                                  maxWidth: !!maxWidth ? maxWidth : "20rem"
                                }}
                                fullWidth={!!fullwidth}
                                helperText={errors[name] && errors[name].message}
                                error={!!errors[name]}
                            />}
                        {...rest}
                    />
                )}
            />
        </LocalizationProvider>
    );
}