import { observer } from "mobx-react-lite";
import { Fragment } from "react"
import {DesktopView, MobileView} from "../../../MediaContext";
import FavoritesDesktop from "./FavoritesDesktop"
import FavoritesMobile from "./FavoritesMobile"
import {SideMenu} from "../MySiteDesktop";
import {useMediaContext} from "../../../MediaContext";
const Favorites = () => {
    const mediaContext = useMediaContext();
    return <SideMenu hide={mediaContext.isMobile}>
    <Fragment>
            <MobileView>
                <FavoritesMobile/>
            </MobileView>
    
            <DesktopView>
                <FavoritesDesktop/>
            </DesktopView>
        </Fragment>
        </SideMenu>
}

export default observer(Favorites);