import {useMediaContext} from "../../MediaContext";
import {Box, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Space from "../Basic/Space";
import DesktopGallery from "./DesktopGallery";
import ContactOwner from "./ContactOwner";
import React from "react";
import Chat from "../Chat/Chat";
import { conditionMap, danishKrone } from "./Post";
import {timestampToDateTime} from "../../Utilities/dateUtils";
import {Ad} from "../Ads/Ad";
import {bannerStore} from "../../Stores/BannerStore";
import {LinkSanitizerWrapper} from "./LinkSanitizerWrapper";
import { observer } from "mobx-react"; 

export const PostDesktop = ({
    loading,
    scrollToMessageArea,
    post
}) => {
    const isMobile = useMediaContext().isMobile;

    const bannerLocation = "D3"
    const banner = bannerStore.banners[bannerLocation];
    const aspectRatio = "4/3";

    return <Box>
        {/* Title */}
        <Typography
            variant="h4"
            sx={{
                fontWeight: 'bold',
                marginTop: (!loading)
                    ? "10px"
                    : undefined
            }}
        >
            {post.Heading}
        </Typography>

        {/* Price and date */}
        <Stack spacing={1} direction="row" alignItems="center">
            <Typography alignItems="flex-start" variant="h4">
                {danishKrone.format(post.Price)}
            </Typography>
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{color: "#474747"}}
            >
                <CalendarMonthIcon fontSize="small"/>
                <Typography>{timestampToDateTime(post.CreationTime)}</Typography>
            </Stack>
        </Stack>
        <Space height="10px"/>

        <Box sx={{display: "inlineFlex",  justifyContent: 'space-between'}}>
            <Box flexGrow={1}>

                {/* Gallery */}
                <DesktopGallery gallery={post.Images}/>

                <Space height="20px"/>
                <Stack spacing={1}>
                    {/* Table */}
                    <TableContainer>
                        <Table sx={{
                            backgroundColor: "#f3f3f4",
                            borderRadius: 1,
                            maxWidth: isMobile ? "100%" : "300px"
                        }}>
                            <TableBody>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell sx={{color: "#7d7d7d"}}>
                                        Tilstand
                                    </TableCell>
                                    <TableCell sx={{width: "100%"}}>
                                        {conditionMap[post.Condition]}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell sx={{color: "#7d7d7d"}}>
                                        Kategori
                                    </TableCell>
                                    <TableCell sx={{width: "100%"}}>
                                        {post.Subcategory.Name}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* Body */}
                    <Space height="5px"/>
                    <LinkSanitizerWrapper text={post.Body} postId={post.PostId}/>
                    {/* Chat */}
                    <Box sx={{p: 1, borderRadius: 1}} id="postMessages">
                        <Chat post={post}/>
                    </Box>
                </Stack>

            </Box>

            {/* Sidebar */}
            <Space width="50px"/>
            <Box width={330}>
                <ContactOwner scrollToMessageArea={scrollToMessageArea} post={post}/>
                <Space height={"5px"}/>
                <Ad
                    banner={banner}
                    aspectRatio={aspectRatio}
                />
            </Box>
        </Box>
    </Box>
}

export default observer(PostDesktop);