import {SnackbarContext} from "./SnackbarProvider";
import {Link, Snackbar, ThemeProvider, Box} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {observer} from "mobx-react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {createTheme} from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import {useMediaContext} from "../../MediaContext";
import Slide from "@mui/material/Slide";

const theme = createTheme({
    palette: {
        primary: {
            main: '#000'
        },
        info: {
            main: '#5e1022'
        },
        warning: {
            main: '#ebcf82',
        }
    }
});

const GlobalSnackbar = () => {
    const mediaContext = useMediaContext();
    const isMobile = mediaContext.isMobile;

    const iconTypes = {
        success: <DoneIcon fontSize="inherit"/>,
        error: <ErrorOutlineIcon fontSize="inherit"/>,
        warning: <WarningAmberIcon fontSize="inherit"/>,
        info: <InfoIcon fontSize="inherit"/>
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert icon={iconTypes[props.icontype]} elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <SnackbarContext.Consumer>
            {({isOpen, message, handleClose, iconType, transition}) => (
                <ThemeProvider theme={theme}>
                    <Snackbar
                        sx={isMobile ? {marginBottom: "10px"} : {}}
                        anchorOrigin={{vertical: 'bottom', horizontal: "center"}}
                        autoHideDuration={5000}
                        TransitionComponent={transition}
                        open={isOpen}
                        onClose={handleClose}
                        style={{
                            "& .MuiAlertAction": {
                                padding: "0px"
                            },
                        }}
                        action={
                            <React.Fragment>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    >
                        <Alert
                            onClose={handleClose}
                            severity="info"
                            icontype={iconType}
                            sx={{
                                width: '100%',
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {typeof message === 'string' ? message : <Box>{message}</Box>}
                        </Alert>
                    </Snackbar>
                </ThemeProvider>
            )}
        </SnackbarContext.Consumer>
    )
}

export default observer(GlobalSnackbar);