import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import Space from "../Basic/Space";
import { reportException, reportMessage } from "../../Utilities/sentry";


const MESSAGES = {
  unsubscribePrompt:
    "Du er ved at afmelde dette nyhedsbrev. Dette betyder, at du ikke længere vil modtage denne notifikation fra Jagtbasen.\n\n" +
    "Hvis du ønsker at fortsætte med afmeldingen, klik venligst på knappen nedenfor.",
  unsubscribeSuccess:
    "Du er nu afmeldt nyhedsbrevet og vil derfor ikke længere modtage denne notifikation fra Jagtbasen.",
  unsubscribeError: "Fejl ved afmelding: ",
  generalError: "Fejl ved afmelding, prøv igen!",
};

const BASE_API_URL = process.env.REACT_APP_API_URL;
const buildApiUrl = (endpoint) => `${BASE_API_URL}${endpoint}`;

// Hjælpefunktion til at håndtere unsubscribe-processen
async function unsubscribeUser(token, unsubscribeAll = false) {
  const url = buildApiUrl('/api/UnsubscribeNotification/unsubscribe');
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Token: token, UnsubscribeAll: unsubscribeAll }),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Ukendt fejl fra server");
    }
  } catch (error) {
    throw new Error("Netværksfejl: " + error.message);
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  logo: {
    width: 140,
    height: 140,
    marginBottom: theme.spacing(4),
  },
  button: {
    background: 'linear-gradient(135deg, #3b7a57 0%, #56ab68 100%)',
    borderRadius: 12,
    color: '#ffffff',
    fontWeight: 'bold',
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    '&:hover': {
      background: 'linear-gradient(135deg, #56ab68 0%, #72c487 100%)',
      transform: 'scale(1.05)',
    },
  },
  homeButton: {
    background: 'linear-gradient(135deg, #003366 0%, #1E90FF 100%)',
    borderRadius: 12,
    color: '#ffffff',
    fontWeight: 'bold',
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    '&:hover': {
      background: 'linear-gradient(135deg, #0055a5 0%, #3399FF 100%)',
      transform: 'scale(1.05)',
    },
  },
}));

function UnsubscribePage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { unSubscribeToken } = useParams();

  const [message, setMessage] = useState(MESSAGES.unsubscribePrompt);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Til at håndtere spinner-feedback

  const handleUnsubscribe = async () => {
    setIsLoading(true); // Vis spinner
    try {
      await unsubscribeUser(unSubscribeToken);
      setMessage(MESSAGES.unsubscribeSuccess); // Sæt succesmeddelelse
      setIsUnsubscribed(true);
    } catch (error) {
      // Log fejlen og opdater brugerbesked
      reportException(error, { options: { url: buildApiUrl('/api/UnsubscribeNotification/unsubscribe') } });
      setMessage(`${MESSAGES.unsubscribeError} ${error.message}`);
    } finally {
      setIsLoading(false); // Fjern spinner
    }
  };

  const handleNavigateToHome = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className={classes.root}>
      <img src="/Images/Logos/Round/Text/150w/600w.webp" alt="Logo" className={classes.logo} />
      <Typography variant="h4" gutterBottom>
        Administrer dine nyhedsbrevpræferencer
      </Typography>
      <Space />
      <Typography
        variant="h6"
        gutterBottom
        paragraph
        style={{ whiteSpace: "pre-line", textAlign: "center" }}
      >
        {message.split(". ").map((line, index) => (
          <React.Fragment key={index}>
            {line.trim() + (index < message.split(". ").length - 1 ? "." : "")}
            <br />
          </React.Fragment>
        ))}
      </Typography>
      <Space />
      {!isUnsubscribed && !isLoading && (
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleUnsubscribe}
        >
          Afmeld nyhedsbrevet
        </Button>
      )}
      {isLoading && <Typography>Venligst vent...</Typography>}
      <Space />
      <Button
        component="a"
        onClick={handleNavigateToHome}
        target="_blank"
        variant="contained"
        className={classes.homeButton}
      >
        Gå til Jagtbasen
      </Button>
    </div>
  );
}

export default observer(UnsubscribePage);
