import { favoriteStore } from "../../../Stores/FavoriteStore";
import { observer } from "mobx-react-lite";

import { useNavigate, Link as RouterLink } from "react-router-dom";
import Space from "../../Basic/Space";
import React from "react";
import { Box, Link, Tab, Table, TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { NavCell, StyledTableBody, StyledTableHeader, StyledTableImage, StyledTableSubTitle } from "../../Shared/Shared";

import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Checkbox from '@mui/material/Checkbox';
import { timeLeft } from "../../../Utilities/dateUtils.ts";

import { deleteFavoriteConfirmation, useConfirmationDialog } from "../../Dialogs/ConfirmationDialog";
import { InfoBox } from "../../MySite/MyPosts/MyPosts";
import {ExpiredOverlay, InactiveOverlay} from "../../MySite/MyPosts/MyPosts";
const TableHeader = (props) => {
    const { numSelected, onSelectAllClick, rowCount } = props;
    return (
        <StyledTableHeader>
            <TableCell padding="checkbox" >
                <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                        'aria-label': 'select all favorites',
                    }}
                />
            </TableCell>
            <TableCell >Annonce</TableCell>
            <TableCell sx={{ width: "100%" }}></TableCell>
            <TableCell><AccessTimeFilledIcon
                style={{
                    paddingTop: "6px",
                    color: "grey",
                    fontSize: "20px",
                }}
            />
            </TableCell>
            <TableCell ></TableCell>
        </StyledTableHeader>
    );
};

function EnhancedTableToolbar(props) {
    const { numSelected, selected } = props;
    const getConfirmation = useConfirmationDialog()
    const handleDeleteFavorites = () => {

        const confirmed = getConfirmation(
            "Slet fra favoritter",
            "Er du sikker på at du vil slette de valgte favoritter?",
            <div />).then((confirmed) => {
                if (confirmed) {
                    favoriteStore.deleteFavorites(selected);
                }
            }).catch((error) => {
                console.error("Error deleting favorite", error);
            });

    }
    return (
        <Box>
            {numSelected > 0 && (
                <Table size="small" sx={{ height: "44px", bgcolor: "grey.200" }}>
                    <TableBody>
                        <TableRow>
                            <TableCell padding="checkbox"><Box sx={{ paddingTop: 6 }} align="center">{numSelected}</Box></TableCell>
                            <TableCell >Markeret</TableCell>
                            <TableCell ></TableCell>
                            <TableCell >
                                <Space height="31px" />
                            </TableCell>
                            <TableCell >
                                <Link
                                    sx={{ display: "block", cursor: "pointer" }}
                                    underline="hover"
                                    onClick={() => handleDeleteFavorites()}
                                    align="right">
                                    Slet
                                </Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>)
            }
        </Box>
    );
}



const FavoritesDesktop = () => {
    const getConfirmation = useConfirmationDialog();
    const navigator = useNavigate();
    const [selected, setSelected] = React.useState([]);
    const favorites = favoriteStore.favorites;

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            const newSelected = favorites.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleDeleteFavorite = (favoriteId) => {
        deleteFavoriteConfirmation(getConfirmation).then((confirmed) => {
            if (confirmed) {
                favoriteStore.deleteFavorite(favoriteId).then(() => {
                    if (selected.includes(favoriteId)){
                        setSelected(selected.filter((id) => id !== favoriteId));
                    }
                });
            }
        }).catch((error) => {
            console.error("Error deleting favorite", error);
        });
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    function navigateTo(postId) {
        navigator("/post/" + postId);
    }



    return (
        <>
            {favorites.length === 0 ? <InfoBox>
                <Typography id='AnonceNotFoundNotification'>
                    Du har ingen favoritter på jagtbasen.dk, men du kan tilføje annoncer til dine favoritter ved at klikke på hjertet på en annonce.
                </Typography>
            </InfoBox> :
                <Box>
                    <Table size="small">
                        <TableBody>
                            <TableHeader
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={favorites.length}
                            />

                            {/* Render favorites favorites */}
                            {favorites.map((favorite, index) => {
                                const isItemSelected = selected.includes(favorite.id);
                                const labelId = `favorites-table-checkbox-${index}`;
                                const to = "/post/" + favorite.postId;
                                const timeleft_info = timeLeft(favorite.postExpirationTime)

                                return (
                                    <TableRow
                                        role="checkbox"
                                        key={"favorites-post-" + favorite.id}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={(event) => handleClick(event, favorite.id)}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        {/* Image */}
                                        <NavCell to={`/post/${favorite.postId}`}>
                                            <StyledTableImage src={favorite.postImages[0]} alt={favorite.postHeading} />
                                        </NavCell>

                                        {/* Title */}
                                        <NavCell to={to}>
                                            <Box sx={{ position: "relative" }}>
                                                {/* Any status overlay */}
                                                {favorite.postActive === false && <InactiveOverlay />}
                                                {timeleft_info === "Udløbet" && <ExpiredOverlay />}

                                                <Space height="5px" />
                                                <StyledTableBody
                                                    onClick={() => {
                                                        navigateTo(favorite.postId);
                                                    }}
                                                >
                                                    {favorite.postBrand != null ? favorite.postBrand + "," : ""}{" "}
                                                    {favorite.postHeading}, {favorite.postBody}
                                                </StyledTableBody>
                                                <StyledTableSubTitle
                                                    onClick={() => {
                                                        navigateTo(favorite.postId);
                                                    }}
                                                >
                                                    {favorite.postPrice} kr.
                                                </StyledTableSubTitle>
                                            </Box>
                                        </NavCell>

                                        <TableCell>
                                            <Typography>
                                                {timeleft_info}
                                            </Typography>
                                        </TableCell>


                                        {/* Actions (Remove from Favorites) */}
                                        <TableCell>
                                            <Link
                                                sx={{ display: "block", cursor: "pointer" }}
                                                underline="hover"
                                                onClick={() => handleDeleteFavorite(favorite.id)}
                                            >
                                                Slet
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    {selected.length > 0 ? <EnhancedTableToolbar numSelected={selected.length} selected={selected} /> : <Space height="44px" />}

                </Box>
            }
        </>
    );
};

export default observer(FavoritesDesktop);
