
import { favoriteStore } from "../../../Stores/FavoriteStore";
import { observer } from "mobx-react-lite";

import { NavLink, useNavigate, Link as RouterLink } from "react-router-dom";
import Space from "../../Basic/Space";
import React, { useState, useEffect } from "react";
import { Box, Link, Tab, Table, TableBody, TableCell, TableRow, Typography, useTheme, Container, ButtonBase, Menu, MenuItem, ListItemText
    
 } from "@mui/material";
import { NavCell, StyledTableBody, StyledTableHeader, StyledTableImage, StyledTableSubTitle } from "../../Shared/Shared";
import { SideMenu } from "../MySiteDesktop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Checkbox from '@mui/material/Checkbox';
import { timeLeft } from "../../../Utilities/dateUtils.ts";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { alpha } from '@mui/material/styles';
import { Spa } from "@material-ui/icons";
import { deleteFavoriteConfirmation, useConfirmationDialog } from "../../Dialogs/ConfirmationDialog";
import { InfoBox } from "../../MySite/MyPosts/MyPosts";
import { TitleBand } from "../MyPosts/MyPostsMobile";
import Column from "../../Basic/Column";
import Row from "../../Basic/Row";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import MenuList from '@mui/material/MenuList';
import { grey } from '@mui/material/colors';
import { set } from "mobx";



const NoFavorites = () => {
    return <React.Fragment>
        <Container>
            <Space height="25px"/>
            <InfoBox>
                <Typography>
                    Du har ingen favoritter. Alle dine favoritter bliver vist på denne side, så snart du har gemt
                    dem.
                </Typography>
            </InfoBox>
        </Container>
    </React.Fragment>
}

function EnhancedTableToolbar(props) {
    const { numSelected, selected, setSelected, handleSelectAllClick, numFavorites } = props;
    const getConfirmation = useConfirmationDialog()
    const handleDeleteFavorites = () => {


        const confirmed = getConfirmation(
            "Slet fra favoritter",
            "Er du sikker på at du vil slette de valgte favoritter?",
            <div />).then((confirmed) => {
                if (confirmed) {
                    favoriteStore.deleteFavorites(selected).then(() => {setSelected([])});
                }
            }).catch((error) => {
                console.error("Error deleting favorite", error);
            });

    }
    return (
        <Box>
            {numSelected > 0 && (
                <Table size="small" sx={{ height: "44px", bgcolor: "grey.200" }}>
                    <TableBody>
                        <TableRow>
                            <TableCell padding="checkbox"><Box sx={{ paddingTop: 6 }} align="center">{numSelected}</Box></TableCell>
                            <TableCell >Markeret</TableCell>
                            <TableCell ><Space height="31px" /></TableCell>
                            <TableCell >
                                
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", justifyContent: "flex-end"}}>
                            <Link
                                    sx={{ display: "block", cursor: "pointer", marginRight: "15px" }}
                                    underline="none"
                                    onClick={handleSelectAllClick}
                                    align="right">
                                    {numSelected === numFavorites ? "Fravælg alle" : "Vælg alle" }
                                </Link>
                                <Link
                                    sx={{ display: "block", cursor: "pointer" }}
                                    underline="none"
                                    onClick={() => handleDeleteFavorites()}
                                    align="right">
                                    Slet
                                </Link>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>)
            }
        </Box>
    );
}

const FavoriteRow = ({favorite, isItemSelected, labelId, handleSelected, numSelected, handleDeleteFavorite}) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const isnotAvailable = favorite.postExpirationTime < Date.now() / 1000 || favorite.postActive === false;
     

    return (
        <Box sx={{ display: "flex", width: "100%" }}>
            <NavLink
                style={{
                    textDecoration: "none",
                    color: "inherit",
                    flexGrow: 1,
                    width: "100%",  // Add this
                    minWidth: 0,    // Add this - important for flex items
                }}
                to={"/post/" + favorite.postId}
            >
                <Box sx={{ 
                    display: "flex", 
                    width: "100%",  // Add this
                    minWidth: 0     // Add this
                }}>
                    <Space width="5px"></Space>

                    {/* IMAGE */}
                    <img
                        style={{
                            height: "75px",
                            minHeight: "75px",
                            width: "75px",
                            minWidth: "75px",
                            objectFit: "cover",
                            borderRadius: "4px",
                            marginTop: "5px",
                            marginBottom: "5px",
                        }}
                        alt="image"
                        src={favorite.postImages[0]}
                    />

                    <Space width="15px"/>

                    <Column style={{
                        flexGrow: 1,
                        minWidth: 0,  // Add this
                        width: "100%" // Add this
                    }}>
                        <Typography
                            align="left"
                            sx={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "100%",
                                opacity: isnotAvailable ? "0.5" : undefined,
                            }}
                        >
                            {favorite.postHeading}, {favorite.postBody}
                        </Typography>
                        <Typography sx={{ opacity: isnotAvailable ? "0.5" : undefined}} align="left">{favorite.postPrice} kr.</Typography>
                        <Row style={{alignItems: "center", fontSize: "12px"}}>
                            <Typography style={{fontSize: "1em"}}>
                                {favorite.postExpirationTime < Date.now() / 1000 && "Udløbet"}
                                {favorite.postActive === false && "Inaktiv"}
                            </Typography>
                        </Row>
                    </Column>
                </Box>
            </NavLink>
            <Space width="10px"/>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
            <IconButton  id="basic-button"
             sx={{alignSelf: "flex-start"}}
             aria-label="moreHorizIcon"
             aria-controls={open ? 'basic-menu' : undefined}
             aria-haspopup="true"
             aria-expanded={open ? 'true' : undefined}
             onClick={handleClick}
             >
                <MoreHorizIcon />
            </IconButton>
            { numSelected > 0 &&
            <Checkbox
                color="primary"
                sx={{alignSelf: "flex-end"}}
                checked={isItemSelected}
                onClick={() => handleSelected(favorite.id)}
                inputProps={{
                    'aria-labelledby': labelId,
                }}
            />}
            <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            >
                <MenuItem sx={{ gap:1, }} onClick={(event) => {handleClose(); handleSelected(favorite.id);}}>
                    <CheckIcon fontSize="small" sx={{color: grey[700]}} />
                    <Typography sx={{color: grey[700]}}>Vælg</Typography>
                </MenuItem>
                <MenuItem sx={{ gap:1, }}  onClick={() => {handleClose(); handleDeleteFavorite(favorite.id);}}>
                   <DeleteIcon fontSize="small" sx={{color: grey[700]}} />
                   <Typography sx={{color: grey[700]}}>Slet</Typography>
                </MenuItem>
            </Menu>
            </Box>
            <Space width="10px"/>
        </Box>
    );
};

const FavoritesMobile = () => {
    const getConfirmation = useConfirmationDialog()
    const [selected, setSelected] = React.useState([]);
    const theme = useTheme();
    const favorites = favoriteStore.favorites;

    const handleSelected = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (favorites.length !== selected.length) {
            const newSelected = favorites.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleDeleteFavorite = (favoriteId) => {
        deleteFavoriteConfirmation(getConfirmation).then((confirmed) => {
            if (confirmed) {
                favoriteStore.deleteFavorite(favoriteId).then(() => {
                    if (selected.includes(favoriteId)){
                        setSelected(selected.filter((id) => id !== favoriteId));
                    }
                });
            }
        }).catch((error) => {
            console.error("Error deleting favorite", error);
        });
    }

    return( 
    <>
   
    {favorites.length === 0 ? <NoFavorites/> :
     <Column>
    
        <TitleBand title="Favoritter"/>
        {favoriteStore.favorites.map((favorite, index) => {
            const isItemSelected = selected.includes(favorite.id);
            const labelId = `favorites-row-checkbox-${index}`;
         
            return (
                
                        <Column style={{
                                paddingTop: index == 0 ? "6px" : "3px",
                                paddingBottom:
                                    index == favorites.length - 1 ? "6px" : "3px",
                                borderTop:
                                    index != 0 ? "1px solid " + theme.palette.grey.A200 : "",
                            }}
                            key={favorite.postId}>
                <FavoriteRow favorite={favorite} isItemSelected={isItemSelected} labelId={labelId} handleSelected={handleSelected} numSelected={selected.length} handleDeleteFavorite={handleDeleteFavorite} />
                </Column>
              
            )
        })}
   
    </Column>
    }
    {selected.length > 0 ? <EnhancedTableToolbar numSelected={selected.length} selected={selected} setSelected={setSelected} handleSelectAllClick={handleSelectAllClick} numFavorites={favorites.length} /> : <Space height="44px" />}
    
    </>
    );
}

export default observer(FavoritesMobile);