import React, {useEffect} from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {LoginContainer} from "../Login/Login";
import Container from "@mui/material/Container";
import {useMediaContext} from "../../../MediaContext";
import Typography from "@mui/material/Typography";
import LoadingButton from "../../Form/LoadingButton";
import {accountStore} from "../../../Stores/AccountStore";
import {reportException} from "../../../Utilities/sentry";
import useSnackbar from "../../Snackbar/SnackbarHook";
import {Analytics, Auth} from "aws-amplify";
import {useLocation, useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import Space from "../../Basic/Space";
import Divider from "../../Basic/Divider";
import {getMitIDConfirmation, useConfirmationDialog} from "../../Dialogs/ConfirmationDialog";

function ThirdFlow() {
    const mediaContext = useMediaContext();
    const navigate = useNavigate();
    const { control, handleSubmit, setValue } = useForm();
    const [allChecked, setAllChecked] = React.useState(false);
    const isMobile = mediaContext.isMobile;
    const [loading, setLoading] = React.useState(false);
    const snackbar = useSnackbar()
    const getConfirmation = useConfirmationDialog();
    const location = useLocation();
    const lastVisitedPage = location.state?.from;
    const [searchParams] = useSearchParams();
    const signUpState = searchParams.get("new") || false;

    const onSubmit = async data => {
        setLoading(true)
        const curUser = await Auth.currentUserInfo();

        let checkedOptions = [];
        for (let [key, value] of Object.entries(data)) {
            if (value) {
                checkedOptions.push({userId:curUser.username, huntingId:optionsWithId[key]});
            }
        }
        try{
            await accountStore.uploadUserHuntingTypes(checkedOptions);
            setLoading(false);
            snackbar.handleOpen("Profil gemt", "success")
            Analytics.record({
                name: "Sign up - success"
            })

            getMitIDConfirmation(getConfirmation).then((confirmed) => {
                if (confirmed) {
                    window.location.href = `${process.env.REACT_APP_API_URL}/api/MitID/${accountStore.user.id}`;
                }
            }).catch((error) => {
                console.error("Error confirming logout", error);
            });

            if (!!lastVisitedPage) {
                if (lastVisitedPage === "/log-ind") {
                    navigate("/", {replace: true});
                } else if (!!lastVisitedPage.search) {
                    navigate(lastVisitedPage.pathname + lastVisitedPage.search, {replace: true});
                } else {
                    navigate(lastVisitedPage.pathname, {replace: true});
                }
            } else {
                navigate("/", {replace: true});
            }
        } catch (e) {
            reportException(e);
            snackbar.handleOpen("Der skete en fejl", "error");
            setLoading(false);
            console.log(e)
            setLoading(false)
        }
    };

    const options = [
        "Fasanjagt", "Andejagt", "Bukkejagt", "Hjortejagt",
        "Drivjagt", "Udlandsjagt", "Vildsvinejagt", "Gåsejagt", "Rævejagt",
        "Grej & udstyr", "Reguleringsjagt", "Buejagt", "Havjagt", "UVjagt"
    ];

    const optionsWithId = {
        "Fasanjagt":1,
        "Andejagt":2,
        "Bukkejagt":3,
        "Hjortejagt":4,
        "Drivjagt":5,
        "Udlandsjagt":6,
        "Vildsvinejagt":7,
        "Gåsejagt":8,
        "Rævejagt":9,
        "Grej & udstyr":10,
        "Reguleringsjagt":11,
        "Buejagt":12,
        "Havjagt":13,
        "UVjagt":14
    }

    const handleAllChecked = (event) => {
        setAllChecked(event.target.checked);
        options.forEach(option => {
            setValue(option, event.target.checked);
        });
    };

    useEffect(() => {
        Analytics.record({
            name: "Sign up - 3. step started"
        })
    }, []);

    return (
        <LoginContainer>
            <Container sx={{padding: "75px 40px 20px 40px"}} maxWidth={ isMobile ? "xs" : "md"}>
                <Typography component="h1" variant="h4" sx={{mb: 3}}>
                    Vælg jagtformer
                </Typography>
                <Typography sx={{mt:2, mb:2}}>
                    Kryds de jagtformer af, du går på, for at vi kan vise dig de mest relevante annoncer.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} style={{maxWidth: "400px"}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allChecked}
                                onChange={handleAllChecked}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#5e1022',
                                    },
                                }}
                            />
                        }
                        label="Vælg alle"
                    />
                    <Space height="5px"/>
                    <Divider sx={{maxWidth: "150px"}}/>
                    <Space height="10px"/>
                    <Grid container spacing={isMobile ? 0.5 : 1}>
                        {options.map((option, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Controller
                                    name={option}
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                    name={option}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#5e1022',
                                                        },
                                                    }}
                                                />
                                            }
                                            label={option}
                                        />
                                    )}
                                    key={option}
                                />
                            </Grid>
                        ))}
                        {!isMobile && <Grid item xs={12} sm={6}/>}
                        <Grid item>
                            <LoadingButton onClick={handleSubmit(onSubmit)} color="info"
                                           sx={{ width: "11em", marginTop: "10px"}} loading={loading}
                                           label={signUpState ? "Gem" : "Opret bruger"} disabledColor="disabledRed"/>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </LoginContainer>
    );
}

export default ThirdFlow;
