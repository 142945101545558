import {Box, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {NavLink as Link, useLocation, useNavigate} from "react-router-dom";
import React, {Fragment} from "react";
import MyPosts from "./MyPosts/MyPosts";
import Divider from "../Basic/Divider";
import Row from "../Basic/Row";
import {AccountAvatar} from "../Shared/Shared";
import {accountStore} from "../../Stores/AccountStore";
import Column from "../Basic/Column";
import Space from "../Basic/Space";


const linkStyle = {
    textDecoration: "none",
    color: "black"
};

const SideMenuItem = ({active, text}) => {
    return <Typography sx={{
        color: active ? "jagtred.main" : undefined,
        fontWeight: active ? "bold" : undefined
    }}>
        {text}
    </Typography>
}

/**
 * The side menu on the left side of the user page
 * It contains links to the different pages
 * The children are the pages that are rendered next to the menu
*/
export const SideMenu = ({children, loading = false, hide = false}) => {
    const location = useLocation();
    const navigate = useNavigate();

    // Get path and remove the first and last slash
    const path = location.pathname.replace(/^\/|\/$/g, '')

    const isProfile = path === "min-side/profil";
    const isPosts = path === "min-side/my-posts";  // Default route
    const isInbox = path === "min-side/min-indbakke";
    const isFavorites = path === "min-side/favoritter";
    if (hide) {
        return <Fragment>
            {children}
        </Fragment>
    }

    return <Row>
        <Box sx={{paddingRight: "50px", width: "250px"}}>
            <Row
                sx={{
                    mb: 2,
                    cursor: "pointer",
                    alignItems: "center"
                }}
                onClick={() => {navigate("/min-side/")}}
            >
                <AccountAvatar
                    firstName={accountStore.user.firstname}
                    lastName={accountStore.user.lastname}/>
                <Space width="10px"/>
                <Column>
                    <Typography
                        variant="h5"
                        sx={{whiteSpace: "nowrap", lineHeight: "20px"}}
                        onClick={() => {navigate("/min-side/")}}
                    >Min side</Typography>
                    <Typography id="userName" sx={{color: "grey.600", lineHeight: "20px"}}>
                        {accountStore.user.firstname} {accountStore.user.lastname}
                    </Typography>
                </Column>
            </Row>
            <Divider/>
            <Stack sx={{ mt: 2 }} spacing={2}>
                <Link to={"/min-side/my-posts"} style={linkStyle}>
                    <SideMenuItem active={isPosts} text={"Mine annoncer"}/>
                </Link>
                <Link to={"/min-side/favoritter"} style={linkStyle}>
                    <SideMenuItem active={isFavorites} text={"Favoritter"}/>
                </Link>
                <Link to={"/min-side/min-indbakke"} style={linkStyle}>
                    <SideMenuItem active={isInbox} text={"Min indbakke"}/>
                </Link>
                <Divider/>
                <Link to={"/min-side/profil"} style={linkStyle}>
                    <SideMenuItem active={isProfile} text={"Kontoindstillinger"}/>
                </Link>
            </Stack>
        </Box>
        {children}
    </Row>
}

export const MySiteDesktop = () => {
    // The default route is the user's posts
    return <MyPosts/>
}