import React, {FC, ReactNode} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Inspiration: https://dev.to/metamodal/control-a-dialog-box-asynchronously-using-react-hooks-4ik7

/*
  HOW TO USE:
  const getConfirmation = useConfirmationDialog()
  
  const confirmed = await getConfirmation(
    "Title",
    "Message",
    <div/>
  );

  if (confirmed) ...
*/

const ConfirmationDialog: FC<{
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  title?: string;
  message?: string;
  node?: ReactNode;
}> = ({ open, onConfirm, onDismiss, title, message, node }) => {
  return (
    <Dialog open={open} onClose={onDismiss}>
      {title != null && <DialogTitle>{title}</DialogTitle>}
      {message != null && (
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
      )}
      {node != null && (
          <DialogContent sx={{paddingTop: 0}}>
            {node}
          </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onDismiss}>Annuller</Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          Bekræft
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type dialogState = {
  open: boolean;
  title?: string;
  message?: string;
  node?: ReactNode;
  callback?: (value: boolean) => void;
};

export type openDialogFunction = (
  callback: (value: boolean) => void,
  title?: string,
  message?: string,
  node?: ReactNode,
) => void;

const ConfirmationDialogContext = React.createContext<openDialogFunction>(
  {} as openDialogFunction
);

export const ConfirmationDialogProvider: FC = ({children}) => {
  const [dialogConfig, setDialogConfig] = React.useState<dialogState>({
    open: false,
  });

  const openDialog: openDialogFunction = (callback, title, message, node) => {
    setDialogConfig({
      open: true,
      callback: callback,
      title: title,
      message: message,
      node: node,
    });
  };

  const resetDialog = () => {
    setDialogConfig({ ...dialogConfig, open: false });
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.callback!(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.callback!(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={openDialog}>
      <ConfirmationDialog
        open={dialogConfig.open}
        title={dialogConfig.title!}
        message={dialogConfig.message!}
        node={dialogConfig.node}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialog = () => {
  const openDialog = React.useContext(ConfirmationDialogContext);

  const getConfirmation = (title?: string, message?: string, node?: ReactNode) =>
    new Promise((res) => {
      openDialog(res, title, message, node);
    });

  return getConfirmation;
};

export const getDeactivatePostConfirmation = (getConfirmation: (
    title?: string,
    message?: string,
    node?: React.ReactNode
) => Promise<boolean>): Promise<boolean> => {
  return getConfirmation(
      "Deaktiver annonce",
      "Når du deaktiverer din annonce, vil den ikke længere være synlig på Jagtbasen. Chatten vil dog stadig være aktiv og annoncen vil stadig kunne tilgås med link.",
  );
}

export const getActivatePostConfirmation = (getConfirmation: (
    title?: string,
    message?: string,
    node?: React.ReactNode
) => Promise<boolean>): Promise<boolean> => {
    return getConfirmation(
        "Aktivér annonce",
        "Når du aktiverer din annonce, vil den igen være synlig på Jagtbasen.",
    );
}

export const getLogoutConfirmation = (getConfirmation: (
    title?: string,
    message?: string,
    node?: React.ReactNode
) => Promise<boolean>): Promise<boolean> => {
  return getConfirmation(
      "Log ud",
      "Er du sikker på, at du ønsker at logge ud?"
  );
}

export const getMitIDConfirmation = (getConfirmation: (
    title?: string,
    message?: string,
    node?: React.ReactNode
) => Promise<boolean>): Promise<boolean> => {
  return getConfirmation(
      "Bekræft din identitet med mitID",
      "For at sikre tryghed og tillid på vores platform tilbyder vi mitID-validering, så både købere og sælgere kan føle sig trygge ved handel."
  );
}

export const deleteFavoriteConfirmation = (getConfirmation: (
  title?: string,
  message?: string,
  node?: React.ReactNode
) => Promise<boolean>): Promise<boolean> => {
return getConfirmation(
    "Slet fra favoritter",
    "Er du sikker på, at du vil slette annoncen fra dine favoritter?"
);
}