import {observer} from "mobx-react-lite";
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useLocation, useSearchParams} from "react-router-dom";
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    Dialog, DialogTitle,
    Stack, ThemeProvider,
} from "@mui/material";
import {useForm} from "react-hook-form";
import {accountStore} from "../../../Stores/AccountStore";
import {Analytics, Auth} from "aws-amplify";
import InputText from "../../Form/InputText";
import CloseIcon from '@mui/icons-material/Close';
import PasswordField from "../../Form/PasswordField";
import useSnackbar from "../../Snackbar/SnackbarHook";
import LoadingButton from "../../Form/LoadingButton";
import {Fragment, useEffect} from "react";
import {createTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Space from "../../Basic/Space";
import {reportException} from "../../../Utilities/sentry";
import { MobileContainer } from "../../Global/Global";
import {useNavigate} from "react-router-dom";
import { favoriteStore } from "../../../Stores/FavoriteStore";

let initialSignInValues = {
    email: "",
    password: ""
}

const loginTheme = createTheme({
    palette: {
        primary: {
            main: '#000'
        },
        info: {
            main: '#5e1022'
        },
        warning: {
            main: '#ebcf82',
        },
        disabledRed: {
            main: '#410B17',
            contrastText: '#fff',
        },
    }
});

/**
 * Container to wrap the different login components
 * such as Login, SignUp etc.
 */
export const LoginContainer = ({children}) => {
    return <ThemeProvider theme={loginTheme}>
        {children}
    </ThemeProvider>
}

const Login = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    if (!!searchParams.get("username")) {
        initialSignInValues.email = searchParams.get("username");
    }

    if (!!searchParams.get("redirection")) {
        location.state = {
            from: { pathname: searchParams.get("redirection")}
        }
    }

    const [user, setUser] = React.useState({});
    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm({defaultValues: initialSignInValues});
    const validation = useForm();
    const validationRegister = validation.register;
    const validationError = validation.formState.errors;
    const validationHandleSubmit = validation.handleSubmit;

    const onSubmit = async data => {
        setLoading(true);
        let email = data.email;
        let password = data.password;
        const lastVisitedPage = location.state?.from;
        try {
            await accountStore.loginCognito(email, password);
            const userAtJB = await accountStore.getUserAtJagtbasen();
            setLoading(false);

            // Record the event
            Analytics.record({
                name: "Login - success"
            })

            if (userAtJB) {
                await favoriteStore.getUserFavorites();
                if (!!lastVisitedPage) {
                    if (lastVisitedPage === "/log-ind") {
                        navigate("/", {replace: true});
                    } else if (!!lastVisitedPage.search) {
                        navigate(lastVisitedPage.pathname + lastVisitedPage.search, {replace: true});
                    } else {
                        navigate(lastVisitedPage.pathname, {replace: true});
                    }
                } else {
                    navigate("/", {replace: true});
                }
            } else {
                navigate("/signup?signUpStatus=verified", {state: {from: location.state?.from}});
            }
        } catch (error) {
            if (error.code === 'UserNotConfirmedException') {
                await Auth.resendSignUp(email);
                setOpen(true);
                setUser(data);
                return;
            }

            if (error.code === "NotAuthorizedException") {
                snackbar.handleOpen("Forkert e-mail eller adgangskode", "error")
                setLoading(false);
                return;
            }

            if (error.code === "UserNotFoundException") {
                snackbar.handleOpen("Brugeren findes ikke.", "error")
                setLoading(false);
                return;
            }

            reportException(error);
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const onVerification = async data => {
        try {
            const confirm = await Auth.confirmSignUp(user.email, data.verificationCode);
            if (confirm === "SUCCESS") {
                setOpen(false);
                await accountStore.loginCognito(user.email, user.password);
                setUser({});
                navigate("/signup?signUpStatus=verified", {state: {from: location.state?.from}});
            }
        } catch (e) {
            reportException(e);
            console.error(e)
        }
    }
    const [showPassword, setShowPassword] = React.useState(false);
    const passwordRegex = /^(?=.*\d)(?=.*[a-zæøå])(?=.*[A-ZÆØÅ])(?!.*[^a-zA-Z0-9_\u00C0-\u017F!@#$%^&*\-]).{8,}$/;

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [loading, setLoading] = React.useState(false);

    const snackbar = useSnackbar();

    useEffect(() => {
        Analytics.record({
            name: "Login - started"
        })
    }, []);

    return (
        <Fragment>
            <LoginContainer>
                <Container sx={{padding: "75px 40px 20px 40px"}} maxWidth="xs">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >

                        <Typography component="h1" textAlign="left" variant="h4">
                            Log ind
                        </Typography><Space height="20px"/>

                        <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)} sx={{width: '100%'}}>
                            <InputText
                                id="email"
                                autoComplete="email"
                                maxWidth="100%"
                                label="E-mail adresse"
                                register={register}
                                options={{
                                    required: "Påkrævet",
                                    pattern: /^\S+@\S+$/i
                                }}
                                errors={errors}
                            />

                            <PasswordField
                                id="password"
                                label="Adgangskode"
                                showPassword={showPassword}
                                handleClickShowPassword={handleClickShowPassword}
                                handleMouseDownPassword={handleMouseDownPassword}
                                register={register}
                                errors={errors}
                                options={{
                                    required: "Påkrævet",
                                    pattern: {
                                        value: passwordRegex,
                                        message: "Et stort bogstav, et tal og 8 karakter"
                                    }
                                }}
                            />
                            <LoadingButton type="submit" color="info" fullWidth loading={loading} label="Log ind"
                                           disabledColor="info"/>
                        </Stack>
                        <Stack
                            sx={{width: '100%', mt: 2}}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography
                                sx={{
                                    "&:hover": {
                                        textDecoration: 'underline',
                                    },
                                    fontWeight: "bold",
                                    color: "#5e1022",
                                    cursor: "pointer",

                                }}
                                onClick={() => navigate('/glemt-adgangskode')}
                            >
                                Glemt adgangskode?
                            </Typography>

                            <Typography
                                sx={{
                                    "&:hover": {
                                        textDecoration: 'underline',
                                    },
                                    cursor: "pointer",
                                }}
                                onClick={() => navigate('/signup', {state: {from: location.state?.from}})}
                            >
                                Har du ikke en bruger? <span
                                style={{color: "#5e1022", fontWeight: 'bold'}}>Tilmeld</span>
                            </Typography>
                        </Stack>

                    </Box>
                </Container>
                <Box component="form">
                    <Dialog open={open}>
                        <DialogTitle>Verificér
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{mb: 2}}>
                                Indtast venligst den kode vi har sendt til din e-mail
                            </DialogContentText>
                            <InputText
                                id="verificationCode"
                                maxWidth="100%"
                                label="Verifikationskode"
                                register={validationRegister}
                                options={{
                                    required: "Påkrævet",
                                    pattern: {
                                        value: /^\d{6}$/,
                                        message: "Indtast seks cifrede kode"
                                    }
                                }}
                                errors={validationError}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                onClick={validationHandleSubmit(onVerification)}
                            >
                                Send
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </LoginContainer>
        </Fragment>);
}
export default observer(Login);