import * as React from "react";
import Typography from "@mui/material/Typography";
import InboxIcon from '@mui/icons-material/Inbox';
import {
    Button,
    ButtonGroup,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SearchFilter from "../../../Search/Filters/SearchFilter";
import {accountStore} from "../../../../Stores/AccountStore";
import {observer} from "mobx-react";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Space from "../../../Basic/Space";
import Row from "../../../Basic/Row";
import Column from "../../../Basic/Column";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
    getLogoutConfirmation,
    useConfirmationDialog
} from "../../../Dialogs/ConfirmationDialog";
import PictureRender from "../../../Shared/Pictures/PictureRender";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const TopBarDesktop = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorElLeftMenu, setAnchorElLeftMenu] = React.useState(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const open = Boolean(anchorElLeftMenu);
    const getConfirmation = useConfirmationDialog();

    const onlogInd = () => {
        navigate('/log-ind/', {state:{ from: location }})
    }
    const navigatToProfile = () => {
        navigate('/min-side/profil')
    }
    const navigateToMyPage = () => {
        navigate('/min-side/')
    }
    const navigatToIndbakke = () => {
        navigate("/min-side/min-indbakke");
    };
    const onOpretAnnonce = () => {
        navigate("/opret-annonce/");
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElLeftMenu(event.currentTarget);
    };

    const handleCloseNavMenu = (event, itemNav) => {
        setAnchorElLeftMenu(null);
        switch (itemNav) {
            case "min side":
                navigateToMyPage()
                break;
            case "profile":
                navigatToProfile()
                break;
            case "indbakke":
                navigatToIndbakke()
                break;
            case "logout":
                getLogoutConfirmation(getConfirmation).then((confirmed) => {
                    if (confirmed) {
                        accountStore.logOutCognito();
                    }
                }).catch((error) => {
                    console.error("Error confirming logout", error);
                });
                break;
            case "posts":
                navigate('/min-side/my-posts');
                break;
            case "favoritter":
                navigate('/min-side/favoritter');
                break;
        }
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    margin: "50px 0 30px 0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <PictureRender image="logo-round-no-text" size={115} onClick={() => navigate("/")}/>
                <SearchFilter showBanner={true}/>
                <div style={{display: "flex"}}>
                    {/* BUTTONS TO THE RIGHT*/}
                    <Column>
                        {/* Opret bruger link */}
                        {!accountStore.logged && (
                            <Row sx={{justifyContent: "end"}}>
                                <Link
                                    underline="hover"
                                    component="button"
                                    color="inherit"
                                    onClick={() => {
                                        navigate("/signup", {state: {from: location}});
                                    }}
                                >
                                    <Typography sx={{lineHeight: "30px"}}>
                                        Opret bruger
                                    </Typography>
                                </Link>
                            </Row>
                        )}

                        {/* Buttons */}
                        <Row>
                            <Button
                                variant="contained"
                                onClick={onOpretAnnonce}
                                color="jagtyellow"
                                sx={{whiteSpace: "nowrap"}}
                            >
                                OPRET ANNONCE
                            </Button>

                            {accountStore.logged && (
                                <React.Fragment>
                                    <ButtonGroup
                                        sx={{ml: "1em"}}
                                        variant="contained"
                                        aria-label="Min side"
                                        color="jagtred"
                                    >
                                        <Button
                                            variant="contained"
                                            color="jagtred"
                                            onClick={navigateToMyPage}
                                            sx={{color: "#ffffff", whiteSpace: "nowrap"}}
                                        >
                                            Min side
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="jagtred"
                                            sx={{color: "#ffffff"}}
                                            aria-label="account of current user"
                                            aria-controls={open ? "menu-appbar" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? "true" : undefined}
                                            onClick={handleOpenNavMenu}
                                            style={{width: "50px"}}
                                        >
                                            {open
                                                ? <ArrowDropUpIcon/>
                                                : <ArrowDropDownIcon/>
                                            }
                                        </Button>
                                    </ButtonGroup>

                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElLeftMenu}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={open}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: {xs: "block"},
                                            mt: 1,
                                            "& .MuiMenu-paper": {
                                                padding: "5px 10px 5px 10px",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "0px",
                                                boxShadow: "1px",
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            onClick={(e) =>
                                                handleCloseNavMenu(e.target.value, "min side")
                                            }
                                        >
                                            <ListItemIcon>
                                                <PersonIcon sx={{color: "#000"}} fontSize="small"/>
                                            </ListItemIcon>
                                            <ListItemText sx={{color: "#000"}}>
                                                Min side
                                            </ListItemText>
                                        </MenuItem>

                                        <MenuItem
                                            onClick={(e) =>
                                                handleCloseNavMenu(e.target.value, "posts")
                                            }
                                        >
                                            <ListItemIcon>
                                                <NewspaperIcon
                                                    sx={{color: "#000"}}
                                                    fontSize="small"
                                                />
                                            </ListItemIcon>
                                            <ListItemText sx={{color: "#000"}}>
                                                Mine annoncer
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) =>
                                                handleCloseNavMenu(e.target.value, "favoritter")
                                            }
                                        >
                                            <ListItemIcon>
                                                <FavoriteBorderIcon
                                                    sx={{color: "#000"}}
                                                    fontSize="small"
                                                />
                                            </ListItemIcon>
                                            <ListItemText sx={{color: "#000"}}>
                                                Mine favoritter
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) =>
                                                handleCloseNavMenu(e.target.value, "indbakke")
                                            }
                                        >
                                            <ListItemIcon>
                                                <InboxIcon fontSize="small" sx={{color: "#000"}}/>
                                            </ListItemIcon>
                                            <ListItemText sx={{color: "#000"}}>Indbakke</ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) =>
                                                handleCloseNavMenu(e.target.value, "logout")
                                            }
                                        >
                                            <ListItemIcon>
                                                <LogoutIcon sx={{color: "#000"}} fontSize="small"/>
                                            </ListItemIcon>
                                            <ListItemText sx={{color: "#000"}}>
                                                Log ud
                                            </ListItemText>
                                        </MenuItem>
                                    </Menu>
                                </React.Fragment>
                            )}

                            {!accountStore.logged && (
                                <Button
                                    variant="contained"
                                    onClick={onlogInd}
                                    color="jagtred"
                                    sx={{color: "#ffffff", whiteSpace: "nowrap", marginLeft: "1em"}}
                                >LOG IND</Button>
                            )}
                        </Row>

                        {!accountStore.logged && (
                            <Space height="30px"/>
                        )}
                    </Column>
                </div>
            </Box>
        </React.Fragment>
    );
};

export default observer(TopBarDesktop)
