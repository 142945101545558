import {makeAutoObservable, observable, runInAction} from "mobx";
import FavoriteService from "../Services/FavoriteService";
import {reportException} from "../Utilities/sentry";

class FavoriteStore {
    favorites = []
    constructor(){
        makeAutoObservable(this, {
            favorites: observable
        })
    }

    async createFavorite (userId, postId) {
        try {
            const response = await FavoriteService.createFavorite(userId, postId);
            this.getUserFavorites();

        } catch (e) {
            reportException(e);       
        }
    }

    async deleteFavorite(favoriteId){
        try {
            const response = await FavoriteService.deleteFavorite(favoriteId)
            this.getUserFavorites();
        } catch (e){
            reportException(e);
        }
    }

    async deleteFavorites(favorites){
        try {
            const response = await FavoriteService.deleteFavorites(favorites)
            this.getUserFavorites();
        } catch (e){
            reportException(e);
        }
    }

    async getUserFavorites(){
        try {
            const response = await FavoriteService.getUserFavorites();
            runInAction(()=>{
                this.favorites = response.filter(item => item.postDeleted === false);
            })
        } catch (e){
            reportException(e)
        }
    }

}

export const favoriteStore = new FavoriteStore();