
/*
  Add certain number of days to a date
  Src: https://stackoverflow.com/questions/563406/how-to-add-days-to-date
 */
import {monthsNoCapDa, monthsNoCapShortDa} from "../Constants/Constants";

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);  // Create new object to avoid mutating original date
  result.setDate(result.getDate() + days);
  return result;
}

/**
 * Returns current unix time
 * @param ms - If true returns time in milliseconds, else seconds
 */
export function getUnixTime({ms = false} = {}): number {
  if (ms) return Date.now().valueOf();
  else return Math.floor(Date.now() / 1000);
}

/*
  Takes a Date object and returns a string like "17. februar 2023"
  If short is true "17. feb" would be returned
 */
export function dateToString(date: Date, short: boolean = false): string {
  if (short) return `${date.getDate()}. ${monthsNoCapShortDa[date.getMonth()]}`
  return `${date.getDate()}. ${monthsNoCapDa[date.getMonth()]} ${date.getFullYear()}`
}

/**
 * Converts Unixtimestamp to readable date
 * @param timestamp unix timestamp
 * @returns {string} date: "2/4 12:45"
 */
export function timestampToDateTime(timestamp: number) {
    let date = new Date(timestamp * 1000);
    const month = date.toLocaleString("da-DK", { month: "long" });
    const time = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }).replace(".",":")
    return (
        "" +
        date.getDate() +
        ". " +
        month +
        " kl. " +
        time
    );
}


/**
 * Converts Unixtimestamp to readable date
 * @param timestamp unix timestamp
 * @returns {string} date: "11.marts.2023"
 */
export function timestampToDate(timestamp: number) {
  let date = new Date(timestamp * 1000);
  const month = date.toLocaleString("da-DK", { month: "long" });
  return (
      "" +
      date.getDate() +
      ". " +
      month +
      " " +
      date.getFullYear()
  );
}

export function timeLeft(unixTimestamp: number): string {
  // Current time
  const now = Math.floor(Date.now() / 1000); // Get the current Unix timestamp in seconds
  const diff = unixTimestamp - now; // Difference in seconds

  if (diff <= 0) {
    return "Udløbet"; // Case for past timestamp
  }

  // Ensure minimum value is 1 minute
  const minValue = Math.max(diff, 60); 

  // Calculate days, hours, and minutes
  const days = Math.floor(minValue / (3600 * 24)); // Number of full days
  const hours = Math.floor((minValue % (3600 * 24)) / 3600); // Remaining hours after days
  const minutes = Math.floor((minValue % 3600) / 60); // Remaining minutes after hours

  // Format the output based on the duration
  if (days > 0) {
    return `${days} dag${days > 1 ? 'e' : ''}`; // Display days if more than 24 hours
  }
  if (hours > 0) {
    return `${hours} time${hours > 1 ? 'r' : ''}`; // Display hours if more than 60 minutes but less than 24 hours
  }
  return `${minutes} minut${minutes > 1 ? 'ter' : ''}`; // Display minutes if less than 60 minutes
}