import accountService from "./AccountService";
import { reportMessage } from "../Utilities/sentry";

class FavoriteService {
    async createFavorite(userId, postId) {
        const jwt = await accountService.getCognitoJwt()
        const favorite = {
            userId,
            postId
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/favorite`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            },
            body: JSON.stringify(favorite),
        });
        
        if (!response.ok) {
            reportMessage(
                "Received status != 200 during favorite creation", {
                options: {
                    status: response.status,
                    message: response.statusText,
                    response: response.clone().text(),
                    favorite: JSON.stringify(favorite)
                }
            }
            );
            throw new Error('Failed to submit favorite');
        }

        const result = await response.json();
        return result;
        
    }

    async deleteFavorite(favoriteId) {
        const jwt = await accountService.getCognitoJwt()

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/favorite/${favoriteId}`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            }
        });
        
        if (!response.ok) {
            reportMessage(
                "Received status != 200 during favorite deletion", {
                options: {
                    status: response.status,
                    message: response.statusText,
                    response: response.clone().text(),
                    favoriteId: favoriteId
                }
            }
            );
            throw new Error('Failed to delete favorite');
        }

        const result = await response.json();
        return result;
        
    }

    async deleteFavorites(favorites) {
        const jwt = await accountService.getCognitoJwt()

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/favorite/bulkdelete`, {
            method: 'POST',
            body: JSON.stringify(favorites),
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            }
        });
        
        if (!response.ok) {
            reportMessage(
                "Received status != 200 during favorite deletion", {
                options: {
                    status: response.status,
                    message: response.statusText,
                    response: response.clone().text(),
                    favorites: favorites
                }
            }
            );
            throw new Error('Failed to delete favorite');
        }

        const result = await response.json();
        return result;
        
    }

    async getUserFavorites() {
        const jwt = await accountService.getCognitoJwt();
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/api/favorite`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${jwt}`,
                }
            });
            if (!result.ok) {
                reportMessage(
                    "Received status != 200 during favorite fetch", {
                    options: {
                        status: result.status,
                        message: result.statusText,
                        response: result.clone().text()
                    }
                }
                );
                throw new Error('Failed to fetch favorite');
            }
            const favorites = await result.json();
            return favorites;
        } catch (e) {
            reportMessage(
                "Failed to get user favorites", {
                options: {
                    error: e
                }
            }
            );
            throw e;
        }
    }
}

export default new FavoriteService();